<template>
  <article class="card">
    <div class="card__wrapper">
      <Column>
        <div class="card__image-holder">
          <router-link :to="link"
                       class="card__image-link">
            <img :src="params.images[0]"
                 class="card__image"
                 alt=""/>
          </router-link>
          <Favorite v-if="params.entity === 'event'"
                    class="card__favorites"
                    :params="{link, favorited: params.favorited}"/>
        </div>
        <router-link :to="link"
                     class="card__info-holder">
          <div class="card__info-type-container">
            <p v-if="params.type" class="card__info-type">{{$type(params.type.name ?
              params.type.name : params.type)}}</p>
            <div v-if="params.type === 'event'" class="card__info-type-event">
              <div v-html="svgImg()"/>
              {{badgeName}}
            </div>
          </div>
          <div class="card__info-title">{{title}}</div>
          <div class="card__info-address">
            {{address}}
          </div>
          <Row class="card__info-icon-date">
            <Icon class="card__info-icon"
                  viewport="0 0 20 20"
                  xlink="calender"/>
            <span class="card__info-date">
              {{params.started_at ? $moment.unix(params.started_at).format(formatDate) :
              $moment.unix(params.created_at).format(formatDate)}}</span>
          </Row>
        </router-link>
      </Column>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Card',
  data() {
    return {
      formatDate: 'DD MMMM, HH:mm',
    };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      return `/${this.params.entity}/${this.params.id}`;
    },
    badgeName() {
      const Badge = this.$badges.find(badge => badge.id === this.params.badge_id);
      return Badge ? Badge.title : '';
    },
    title() {
      const count = this.$viewport.desktop ? 60 : 35;
      return this.trim(this.params.title || this.params.name, count);
    },
    address() {
      const count = this.$viewport.desktop ? 70 : 35;
      return this.trim(this.params.place ? this.params.place.address : this.params.address, count);
    },
  },
  methods: {
    trim(text, count) {
      if (!text) return '';
      let string = text;
      if (string.length > count) {
        string = string.slice(0, count);
        string = `${string}...`;
      }
      return string;
    },
    svgImg() {
      if (!this.$badgesSVG.length || this.params.badge_id === null) {
        return '<div></div>';
      }
      const div = document.createElement('div');
      div.innerHTML = this.$badgesSVG.find(svg => svg.id === this.params.badge_id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.setAttribute('width', '32px');
      svg.setAttribute('height', '32px');
      div.getElementsByClassName('color-marker-symbol')
        .forEach((el) => {
          const element = el;
          const badgeIconSettings = this.params.badge ? this.params.badge.icon_settings : undefined;
          element.style.fill = badgeIconSettings ? badgeIconSettings.color : '#000000';
          element.style.opacity = badgeIconSettings ? badgeIconSettings.transparency : 1;
        });
      return div.innerHTML;
    },
  },
};
</script>
